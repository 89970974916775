<template>
  <vs-prompt :active="show" class="modal-clients" buttons-hidden>
    <div class="mb-5 flex justify-between text-right">
      <h3>{{ $t("Clientes") }}</h3>
      <feather-icon icon="XIcon" @click="closeModal" class="cursor-pointer" />
    </div>
    <div class="mt-4">
      <vs-table
        class="promotions-clients-table"
        :data="clients"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Correo") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- Nombre -->
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <!-- Identification -->
            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-prompt>
</template>

<script>
import VSelect from "vue-select";
export default {
  name: "ModalDetailClients",
  components: {
    VSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    clients: {
      type: Array,
      default: null,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-clients .vs-dialog {
  max-width: 750px;
}
.modal-clients .vs-dialog-header {
  display: none;
}
</style>
