<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Detalles de promoción") }}</h3>
      <div v-if="promotion && !loading" class="grid grid-cols-2 gap-5">
        <div class="w-full">
          <label for="promotion-name"
            >{{ $t("Nombre de la promoción") }}:</label
          >
          <vs-input
            v-model="promotion.promotion_name"
            id="promotion-name"
            class="w-full pointer-events-none"
          />
        </div>
        <div>
          <label for="promotion-type">{{ $t("Tipo de promoción") }}:</label>
          <vs-input
            v-model="promotion.name_type_promotions"
            id="promotion-name"
            class="w-full pointer-events-none"
          />
        </div>
        <div>
          <label>{{ $t("Reglas") }}:</label>
          <vs-input
            v-for="rule in promotion.promotion_rules"
            v-model="rule.name"
            :key="rule.name"
            class="w-full pointer-events-none"
          />
        </div>
        <div class="col-span-full">
          <label for="promotion-description">{{ $t("Descripción") }}:</label>

          <vs-textarea
            v-model="promotion.description_promotion"
            class="w-full mb-0 pointer-events-none"
            id="promotion-description"
          />
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de inicio") }}:</label>
          <vs-input
            type="date"
            v-model="promotion.date_init"
            id="promotion-name"
            class="w-full pointer-events-none"
          />
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de inicio") }}:</label>
          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="promotion.init_hour"
            class="w-full pointer-events-none"
            :disabled="true"
          >
          </vSelect>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de fin") }}:</label>
          <vs-input
            type="date"
            v-model="promotion.date_end"
            id="promotion-name"
            class="w-full pointer-events-none"
          />
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de fin") }}:</label>

          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="promotion.end_hour"
            class="w-full pointer-events-none"
            :disabled="true"
          />
        </div>
        <div class="col-span-2">
          <label for="promotion-products">{{ $t("Productos") }}:</label>
          <div class="flex justify-between items-center">
            <span>
              {{ $t("Total de productos en la promoción") }}:
              {{ promotion.products.length }}
            </span>
            <vs-button @click.native="showModalProducts = true"
              >Ver lista</vs-button
            >
          </div>
          <ModalDetailProducts
            :show="showModalProducts"
            :products="promotion.products"
            @close="showModalProducts = false"
          />
        </div>
        <div class="col-span-2">
          <label for="promotion-clients">{{ $t("Clientes") }}:</label>
          <div class="flex justify-between items-center">
            <p class="font-light">
              {{ $t("Total de clientes en la promoción") }}:
              {{ promotion.customers.length }}
            </p>
            <vs-button @click.native="showModalClients = true">{{
              $t("Ver lista")
            }}</vs-button>
          </div>
          <ModalDetailClients
            :show="showModalClients"
            :clients="promotion.customers"
            @close="showModalClients = false"
          />
        </div>
      </div>
      <div v-else class="my-20 text-center">
        <feather-icon icon="RefreshCwIcon" class="animate-spin"></feather-icon>
      </div>

      <div class="mt-10 flex justify-end gap-5">
        <vs-button class="rounded-full" @click.native="$router.go(-1)">
          {{ $t("Volver") }}
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import VSelect from "vue-select";
import http from "@/http/promotions-http-client.js";
import hours from "@/assets/utils/hours";
import ModalDetailProducts from "./ModalDetailProducts.vue";
import ModalDetailClients from "./ModalDetailClients.vue";
export default {
  name: "DetailPromotionPage",
  components: {
    ModalDetailProducts,
    ModalDetailClients,
    VSelect,
  },

  data() {
    return {
      promotion: null,
      loading: false,
      optionHours: hours,
      promotionRules: [],
      promotionTypes: [],
      showModalProducts: false,
      showModalClients: false,
    };
  },
  async created() {
    await this.getPromotion();

    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Promociones"),
      subtitle: this.$t("Ver detalles de una promoción"),
    });
  },
  methods: {
    async getPromotion() {
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.get(`api/CustonPromotionsSh/${id}`);
      this.loading = false;
      if (!response || response.status !== 200) {
        this.$router.go(-1);
        return;
      }
      const products = response.data.products.map((item) => ({
        ...item,
        sku: item.sku.split("_")[1],
      }));
      this.promotion = {
        ...response.data,
        products: products,
      };
    },
    dateToString(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }

      if (month < 10) {
        month = `0${month}`;
      }
      const dateString = year + "-" + month + "-" + day;

      return dateString;
    },
  },
};
</script>
