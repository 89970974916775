<template>
  <!-- modal eliminar -->
  <vs-prompt
    :active="show"
    title="Productos"
    class="modal-products"
    buttons-hidden
  >
    <div class="mb-5 flex justify-between text-right">
      <h3>{{ $t("Productos") }}</h3>
      <feather-icon icon="XIcon" @click="closeModal" class="cursor-pointer" />
    </div>
    <div class="mt-4">
      <vs-table
        class="promotions-products-table"
        :data="products"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Cantidad") }}</vs-th>
          <vs-th>{{ $t("Sku") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- Nombre -->
            <vs-td :data="data[indextr].title">
              {{ data[indextr].title }}
            </vs-td>

            <!-- Cantidad según bonificacion -->
            <vs-td :data="data[indextr].trigger_qunt">
              {{ data[indextr].trigger_qunt || "-" }}
            </vs-td>

            <!-- Sku -->
            <vs-td :data="data[indextr].sku">
              {{ data[indextr].sku }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-prompt>
</template>

<script>
import VSelect from "vue-select";
export default {
  name: "ModalDetailProducts",
  components: {
    VSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: null,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-products .vs-dialog {
  max-width: 750px;
}
.modal-products .vs-dialog-header {
  display: none;
}
</style>
